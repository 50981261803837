import React, { Suspense, lazy } from 'react';
import { Redirect, Switch, useRouteMatch } from 'react-router-dom';
import Route from 'components/Route';
import Page from 'components/Page';
import SectionSpinner from 'components/SectionSpinner';

const Login = lazy(() => import('features/Auth/Pages/Login'));

const App = () => {
  const { path } = useRouteMatch();

  return (
    <Page hideSidebar>
      <Suspense fallback={<SectionSpinner />}>
        <Switch>
          <Route
            path={`${path}`}
            exact
            component={Login}
          />
          <Redirect to={path} />
        </Switch>
      </Suspense>
    </Page>
  );
};

export default App;
