import React, {
  PropsWithChildren, useCallback, useEffect, useMemo, useState,
} from 'react';
import useIsMount from 'hooks/useIsMount';
import useInterval from 'hooks/useInterval';
import { axiosInstance } from 'lib/axios';
import MaintenanceMode from 'features/maintenance-mode/pages/MaintenanceMode';

interface MaintenanceModeContextValues {
  inMaintenanceMode: boolean;
}

const MaintenanceModeContext = React.createContext<MaintenanceModeContextValues>({
  inMaintenanceMode: false,
});

function MaintenanceModeProvider({ children }: PropsWithChildren<{}>) {
  const [inMaintenanceMode, setInMaintenanceMode] = useState<boolean>(false);
  const isMount = useIsMount();

  useEffect(() => {
    if (isMount) {
      axiosInstance.interceptors.response.use(
        async (response) => {
          if (inMaintenanceMode) {
            setInMaintenanceMode(() => false);
          }
          return response;
        },
        async (error) => {
          if (error.response && error.response.status === 503) {
            setInMaintenanceMode(() => true);
          } else {
            setInMaintenanceMode(() => false);
          }
          return Promise.reject(error);
        },
      );
    }
  }, [inMaintenanceMode, isMount]);

  const checkIfOut = useCallback(() => {
    if (inMaintenanceMode) {
      // TODO Replace with a proper status or health check end point on API.
      axiosInstance.get('/api/auth/user');
    }
  }, [inMaintenanceMode]);

  useInterval(checkIfOut, 30000);

  const contextValues = useMemo<MaintenanceModeContextValues>(
    () => ({ inMaintenanceMode }),
    [inMaintenanceMode],
  );

  return (
    <MaintenanceModeContext.Provider value={contextValues}>
      {inMaintenanceMode ? <MaintenanceMode /> : children}
    </MaintenanceModeContext.Provider>
  );
}

function useMaintenanceMode() {
  const context = React.useContext(MaintenanceModeContext);
  if (context === undefined) {
    throw new Error('useMaintenanceMode must be used within a MaintenanceModeProvider');
  }
  return context;
}

export { MaintenanceModeProvider, useMaintenanceMode };
