import Section from 'components/Section/Section';
import * as React from 'react';
import Spinner from 'react-bootstrap/Spinner';

const SectionSpinner = () => (
  <Section>
    <Section.Body>
      <div className="d-flex justify-content-center">
        <Spinner animation="border" role="status">
          <span className="sr-only">Loading...</span>
        </Spinner>
      </div>
    </Section.Body>
  </Section>
);

export default SectionSpinner;
