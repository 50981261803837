import React, { PropsWithChildren, useMemo, useState } from 'react';
import { CanCallback } from 'features/Auth/TenantContext';
import { FeatureSettings } from 'features/Auth/Interfaces/types';

export interface MenuContextValues {
  tenantName: string | false;
  setTenantName: (tenantName?: string) => void;
  tenantSlug: string | false;
  setTenantSlug: (tenantSlug?: string) => void;
  canCallback: CanCallback | false;
  setCanCallback: (canCallback?: CanCallback) => void;
  tenantFeatures: FeatureSettings | false;
  setTenantFeatures: (tenantFeatures?: FeatureSettings) => void;
}

const MenuContext = React.createContext<MenuContextValues>(
  undefined as unknown as MenuContextValues,
);

const MenuProvider = ({ children }: PropsWithChildren) => {
  const [tenantSlug, setTenantSlug] = useState<string | false>(false);
  const [tenantName, setTenantName] = useState<string | false>(false);
  const [canCallback, setCanCallback] = useState<CanCallback | false>(false);
  const [tenantFeatures, setTenantFeatures] = useState<FeatureSettings | false>(false);

  const value = useMemo<MenuContextValues>(
    () => ({
      tenantName,
      setTenantName: (newName?: string) => setTenantName(newName ?? false),
      tenantSlug,
      setTenantSlug: (newSlug?: string) => setTenantSlug(newSlug ?? false),
      canCallback,
      setCanCallback: (newCanCallback?: CanCallback) => setCanCallback(
        // Wrapped in an anonymous function otherwise setState will execute
        // the newCanCallback directly.
        // @see https://stackoverflow.com/questions/55621212/is-it-possible-to-react-usestate-in-react
        () => newCanCallback ?? false,
      ),
      tenantFeatures,
      setTenantFeatures: (newTenantFeatures?: FeatureSettings) => setTenantFeatures(
        newTenantFeatures ?? false,
      ),
    }),
    [tenantName, tenantSlug, canCallback, tenantFeatures],
  );

  return (
    <MenuContext.Provider value={value}>
      {children}
    </MenuContext.Provider>
  );
};

function useMenu(): MenuContextValues {
  const context = React.useContext(MenuContext);
  if (context === undefined) {
    throw new Error('useMenu must be used within a MenuProvider');
  }
  return context;
}

export { MenuProvider, useMenu };
