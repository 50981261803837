import React from 'react';
import { useAuth } from 'features/Auth/AuthContext';
import { Redirect } from 'react-router-dom';
import _ from 'lodash';

const CheckDefaultTenant = () => {
  const { user } = useAuth();

  if (!user?.default_tenant_id) {
    return <Redirect to="/tenant-select" />;
  }

  const defaultTenant = _.find(
    (user.tenant_users ?? []).map(({ tenant }) => tenant),
    { id: user.default_tenant_id },
  );

  if (defaultTenant) {
    return <Redirect to={`/${defaultTenant.url_slug}/dashboard`} />;
  }

  return <Redirect to="/tenant-select" />;
};

export default CheckDefaultTenant;
