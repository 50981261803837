import React, {
  PropsWithChildren, useCallback, useEffect, useState,
} from 'react';
import { QueryClient, QueryKey, useQueryClient } from 'react-query';
import { QueryClientConfig } from 'react-query/types/core/types';

const queryConfig: QueryClientConfig = {
  defaultOptions: {
    queries: {
      staleTime: 5 * 60000,
      refetchOnWindowFocus: false,
      useErrorBoundary: true,
      // required for pagination
      keepPreviousData: true,
    },
  },
};

const queryClient = new QueryClient(queryConfig);

const INVALIDATE_DELAY_IN_MS = 2 * 1000; // 2 second

type InvalidateQueryContextCallback = (queryKey: QueryKey) => void;

const InvalidateQueryContext = React.createContext<InvalidateQueryContextCallback>(
  undefined as unknown as InvalidateQueryContextCallback,
);

// Initial simple provider for holding onto the keys that need invalidated.
// It's super basic with how it handles timeouts as it doesn't set individual
// timeouts for each QueryKey that's added, meaning that with each query key
// being added the timeout is reset. This should still be sufficient for most
// use cases, especially with a timeout on the shorter side.
function InvalidateQueryProvider(props: PropsWithChildren) {
  const [toClear, setToClear] = useState<QueryKey[]>([]);
  const usedQueryClient = useQueryClient();

  const clearingCallback = useCallback(() => {
    toClear.forEach((query) => {
      // Clear the value
      usedQueryClient.invalidateQueries(query);
      // Remove the value from list
      setToClear((prevState) => {
        return prevState.filter((sub) => JSON.stringify(sub) !== JSON.stringify(query));
      });
    });
  }, [toClear, setToClear, usedQueryClient]);

  useEffect(() => {
    const id = setTimeout(clearingCallback, INVALIDATE_DELAY_IN_MS);
    return () => clearTimeout(id);
  }, [clearingCallback]);

  const addQueryToInvalidate = useCallback<InvalidateQueryContextCallback>(
    (queryKey: QueryKey) => {
      setToClear((prevState) => [...prevState, queryKey]);
    },
    [setToClear],
  );

  return (
    <InvalidateQueryContext.Provider
      value={addQueryToInvalidate}
      {...props}
    />
  );
}

function useInvalidateQuery() {
  const context = React.useContext(InvalidateQueryContext);
  if (context === undefined) {
    throw new Error('useInvalidateQuery must be used within a InvalidateQueryProvider');
  }
  return context;
}

export { queryClient, InvalidateQueryProvider, useInvalidateQuery };
