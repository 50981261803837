import React, { useCallback, useMemo, useState } from 'react';
import Nav from 'react-bootstrap/Nav';
import { NavItem } from 'react-bootstrap';
import { NavLink, useLocation } from 'react-router-dom';
import { menuLinks } from 'components/SidebarNav/menu';
import { useTranslation } from 'react-i18next';
import { SidebarNavGroup } from 'components/SidebarNav/types';
import { useAuth } from 'features/Auth/AuthContext';
import Navbar from 'react-bootstrap/Navbar';
import Button from 'react-bootstrap/Button';
import Collapse from 'react-bootstrap/Collapse';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faTimes } from '@fortawesome/free-solid-svg-icons/faTimes';
import classNames from 'classnames';
import logo from 'assets/images/routeique-logo.svg';
import icon from 'assets/images/routeique-icon.svg';
import { faChevronDown } from '@fortawesome/free-solid-svg-icons';
import { useMenu } from 'components/SidebarNav/MenuContext';
import { MENU_WIDTH_OVERLAY } from 'config';

export interface SidebarNavProps {
  isOpen: boolean;
  close: () => void;
}

export interface SidebarNavSectionProps {
  group: SidebarNavGroup;
  handleLinkSelection: () => void;
}

const SidebarNavSection = ({
  group: { groupTranslationKey, items },
  handleLinkSelection,
}: SidebarNavSectionProps) => {
  const { pathname } = useLocation();
  const [open, setOpen] = useState<boolean>(() => {
    return items.map(({ to }) => to).indexOf(pathname) > -1;
  });
  const { t } = useTranslation('shared');

  return (
    <div className={classNames('sub-menu-group py-2', { open })}>
      <div
        onClick={() => setOpen(!open)}
        role="menuitem"
        tabIndex={0}
        className="sub-menu-header font-weight-bold d-flex align-items-center justify-content-between font-lg px-3"
      >
        {t(`menu_group.${groupTranslationKey}`)}
        <FontAwesomeIcon
          rotation={open ? 180 : undefined}
          icon={faChevronDown}
          size="xs"
        />
      </div>
      <Collapse in={open} className="pt-2">
        <div>
          {items.map(({ label, to }) => (
            <NavItem key={label}>
              <Nav.Link
                as={NavLink}
                to={to}
                eventKey={to}
                onSelect={handleLinkSelection}
                exact
              >
                {t(`menu_item.${label}`)}
              </Nav.Link>
            </NavItem>
          ))}
        </div>
      </Collapse>
    </div>
  );
};

const SidebarNav = ({ isOpen, close }: SidebarNavProps) => {
  const { t } = useTranslation('shared');
  const { pathname } = useLocation();
  const {
    tenantName, tenantSlug, canCallback, tenantFeatures,
  } = useMenu();
  const { user } = useAuth();

  const processedLinks = useMemo<SidebarNavGroup[]>(
    () => menuLinks
      .map(({ groupTranslationKey, items }) => ({
        groupTranslationKey,
        items: items
          .filter(({
            publicFacing, permission, or, feature,
          }) => {
            if (publicFacing) {
              return true;
            }
            if (!user) {
              return false;
            }
            if (feature && tenantFeatures && !tenantFeatures[feature]) {
              return false;
            }
            return (canCallback && permission)
              ? canCallback(permission, or)
              : true;
          })
          .map(({ label, to }) => ({
            label,
            to: to.replace('__TENANT_SLUG__', tenantSlug === false ? '' : tenantSlug),
          })),
      }))
      .filter(({ items }) => items.length > 0),
    [user, tenantFeatures, canCallback, tenantSlug],
  );

  const navSidebarClasses = classNames('navbar-dark text-white', {
    active: isOpen,
  });

  // Close the menu on link selection under the same break point that triggers
  // the menu to overlay the content, rather than be beside the content
  const handleLinkSelection = useCallback(() => {
    if (window.innerWidth <= MENU_WIDTH_OVERLAY) {
      close();
    }
  }, [close]);

  return (
    <div id="nav-sidebar" className={navSidebarClasses}>
      <Navbar>
        <Navbar.Brand
          as={NavLink}
          to={tenantSlug ? `/${tenantSlug}/dashboard` : '/'}
          className="font-weight-bold"
        >
          <img
            src={tenantName ? icon : logo}
            className={classNames({ 'pr-2': tenantName })}
            alt={t('menu_item.routeique')}
          />
          {tenantName || null}
        </Navbar.Brand>
        {isOpen && (
          <Button
            variant="link"
            id="menu-close-button"
            onClick={close}
          >
            <FontAwesomeIcon icon={faTimes} />
          </Button>
        )}
      </Navbar>
      <Nav activeKey={pathname} className="navbar-dark flex-column menu-list">
        {tenantSlug && processedLinks.map((group) => (
          <SidebarNavSection
            key={group.groupTranslationKey}
            group={group}
            handleLinkSelection={handleLinkSelection}
          />
        ))}
      </Nav>
    </div>
  );
};

export default SidebarNav;
