import React from 'react';
import { useTranslation } from 'react-i18next';
import logo from 'assets/images/routeique-logo-old.png';
import { Button } from 'react-bootstrap';

const MaintenanceMode = () => {
  const { t } = useTranslation('maintenance_mode');
  return (
    <div className="container d-flex h-100 text-center justify-content-center">
      <div className="align-self-center">
        <div className="row">
          <div className="col-sm-12">
            <i className="far fa-exclamation-triangle display-1 mb-4" />
            <h3 className="mt-3">
              <strong>{t('headings.title')}</strong>
            </h3>
            <p>
              {t('messages.in_maintenance')}
            </p>
            <a href="/">
              <button type="button" className="btn btn-outline-success rounded">
                {t('actions.return_home')}
              </button>
            </a>
          </div>
        </div>
        <div className="row mt-4">
          <div className="col-sm-12">
            <h5>{t('headings.support_header')}</h5>
            <Button
              variant="outline-success"
              href="mailto:servicedesk@routeique.com"
            >
              <strong>{t('actions.contact_support')}</strong>
            </Button>
          </div>
        </div>
        <footer className="footer fixed-bottom">
          <img
            className="mb-3"
            width="350"
            height="110"
            src={logo}
          />
        </footer>
      </div>
    </div>
  );
};

export default MaintenanceMode;
