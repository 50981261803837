import { AxiosResponse } from 'axios';
import { axiosInsecure, axiosInstance } from 'lib/axios';
import { User } from 'features/Auth/Interfaces/types';
import { UpdateUserPasswordRequest, UpdateUserRequest } from 'features/Auth/Interfaces/requests';
import { ResultSet } from 'utils/routeique-api/types';

const getUser = async (): Promise<User> => {
  return axiosInsecure({
    method: 'get',
    url: '/api/auth/user',
  }).then(({ data: { data } }) => data);
};

const signIn = async (email: string, password: string, remember: boolean): Promise<User> => {
  // Login...
  await axiosInsecure({
    data: {
      email,
      password,
      remember,
    },
    method: 'post',
    url: '/login',
  });
  return getUser();
};

const signOut = async () => {
  await axiosInstance({
    method: 'post',
    url: '/logout',
  });
};

const updateUser = async (data: UpdateUserRequest): Promise<AxiosResponse<ResultSet<User>>> => {
  return axiosInstance({
    data,
    method: 'put',
    url: '/api/auth/user',
  });
};

const changePassword = async (data: UpdateUserPasswordRequest): Promise<AxiosResponse<void>> => {
  return axiosInstance({
    data,
    method: 'put',
    url: '/api/auth/user-password',
  });
};

export {
  signIn, getUser, updateUser, signOut, changePassword,
};
