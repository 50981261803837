import routeiqueApiRequest from 'utils/routeique-api/routeiqueApiRequest';
import { StringOptionBase } from 'components/BootstrapReactSelect';
import { TenantSettings } from 'features/Auth/Interfaces/types';
import { ResultSet } from '../../Shared/Interfaces/api/api';
import { SettingsSaveRequest } from '../Interfaces/types';

export default class SettingsService {
  public static getSettings(tenant_id: number) {
    return routeiqueApiRequest<ResultSet<TenantSettings>>(
      `${tenant_id}/settings`,
      'get',
    );
  }

  public static postSettings(
    tenant_id: number,
    values: SettingsSaveRequest,
  ) {
    return routeiqueApiRequest<void>(
      `${tenant_id}/settings`,
      'post',
      values,
    );
  }

  public static supportedCurrencies() {
    return routeiqueApiRequest<ResultSet<string[]>>(
      'settings/supported-currencies',
      'get',
    );
  }

  public static supportedLocales() {
    return routeiqueApiRequest<ResultSet<string[]>>(
      'settings/supported-locales',
      'get',
    );
  }

  public static invoiceColumnOptions(tenant_id: number) {
    return routeiqueApiRequest<ResultSet<StringOptionBase[]>>(
      `${tenant_id}/settings/invoice-column-options`,
      'get',
    );
  }

  public static purchaseOrderDocumentColumnOptions(
    tenant_id: number,
  ) {
    return routeiqueApiRequest<ResultSet<StringOptionBase[]>>(
      `${tenant_id}/settings/purchase-order-document-column-options`,
      'get',
    );
  }

  public static goodsReceiptNoteItemColumnOptions(
    tenant_id:number,
  ) {
    return routeiqueApiRequest<ResultSet<StringOptionBase[]>>(
      `${tenant_id}/settings/goods-receipt-note-item-column-options`,
      'get',
    );
  }

  public static goodsReceiptNoteLotColumnOptions() {
    return routeiqueApiRequest<ResultSet<StringOptionBase[]>>(
      'settings/goods-receipt-note-lot-column-options',
      'get',
    );
  }

  public static packingSlipColumnOptions(tenant_id: number) {
    return routeiqueApiRequest<ResultSet<StringOptionBase[]>>(
      `${tenant_id}/settings/packing-slip-column-options`,
      'get',
    );
  }

  public static pickSlipColumnOptions(tenant_id: number) {
    return routeiqueApiRequest<ResultSet<StringOptionBase[]>>(
      `${tenant_id}/settings/pick-slip/column-options`,
      'get',
    );
  }

  public static warehouseTransferDocumentColumnOptions(
    tenant_id: number,
  ) {
    return routeiqueApiRequest<ResultSet<StringOptionBase[]>>(
      `${tenant_id}/settings/warehouse-transfer-document-column-options`,
      'get',
    );
  }

  public static warehouseTransferDocumentOrdersColumnOptions(
    tenant_id: number,
  ) {
    return routeiqueApiRequest<ResultSet<StringOptionBase[]>>(
      `${tenant_id}/settings/warehouse-transfer-document-orders-column-options`,
      'get',
    );
  }

  public static warehouseReceivingDocumentColumnOptions(
    tenant_id: number,
  ) {
    return routeiqueApiRequest<ResultSet<StringOptionBase[]>>(
      `${tenant_id}/settings/warehouse-receiving-document-column-options`,
      'get',
    );
  }

  public static warehouseReceivingDocumentOrdersColumnOptions(
    tenant_id: number,
  ) {
    return routeiqueApiRequest<ResultSet<StringOptionBase[]>>(
      `${tenant_id}/settings/warehouse-receiving-document-orders-column-options`,
      'get',
    );
  }

  public static warehouseTransferPackingSlipDocumentColumnOptions(
    tenant_id: number,
  ) {
    return routeiqueApiRequest<ResultSet<StringOptionBase[]>>(
      `${tenant_id}/settings/warehouse-transfer-packing-slip-column-options`,
      'get',
    );
  }

  public static weightUnitOptions() {
    return routeiqueApiRequest<ResultSet<StringOptionBase[]>>(
      'settings/weight-unit-options',
      'get',
    );
  }

  public static volumeUnitOptions() {
    return routeiqueApiRequest<ResultSet<StringOptionBase[]>>(
      'settings/volume-unit-options',
      'get',
    );
  }
}
