import React, { Suspense, lazy } from 'react';
import { Redirect, Switch } from 'react-router-dom';
import Route from 'components/Route';
import { useAuth } from 'features/Auth/AuthContext';
import { TenantProvider } from 'features/Auth/TenantContext';
import CheckDefaultTenant from 'features/Auth/Pages/CheckDefaultTenant';
import Page from 'components/Page';
import SectionSpinner from 'components/SectionSpinner';

// We code split out each major group of routes to keep our
// bundle sizes down.
// @see https://reactjs.org/docs/code-splitting.html
const Account = lazy(() => import('features/Auth/Router/Account'));
const Dashboard = lazy(() => import('features/Reports/Pages/Dashboard'));
const TenantSelect = lazy(() => import('features/Auth/Pages/TenantSelect'));
const SeasonReadiness = lazy(() => import('features/SeasonReadiness/Pages/ReadinessRedirect'));
const Suppliers = lazy(() => import('features/Suppliers/Router/SupplierRoutes'));
const PricePlans = lazy(() => import('features/PricePlans/Router/PricePlanRoutes'));
const Storage = lazy(() => import('features/Storage/Router/StorageRoutes'));
const Vocabulary = lazy(() => import('features/Vocabulary/Router/VocabularyRoutes'));
const StockTransfers = lazy(() => import('features/StockTransfers/Router/StockTransferRoutes'));
const StockClaims = lazy(() => import('features/StockClaims/Router/StockClaimsRoutes'));
const GoodsReceipts = lazy(() => import('features/InboundLogistics/Router/GoodsReceiptRoutes'));
const PurchaseOrders = lazy(() => import('features/InboundLogistics/Router/PurchaseOrderRoutes'));
const Orders = lazy(() => import('features/Orders/Router/OrdersRoutes'));
const Customers = lazy(() => import('features/Customers/Router/CustomerRoutes'));
const Products = lazy(() => import('features/Products/Router/ProductRoutes'));
const StockAdjustments = lazy(() => import('features/StockAdjustments/Router/StockAdjustmentsRoutes'));
const Settings = lazy(() => import('features/Settings/Router/SettingsRoutes'));
const Reports = lazy(() => import('features/Reports/Router/ReportRoutes'));
const Imports = lazy(() => import('features/Imports/Router/ImportRoutes'));
const ImportsMapping = lazy(() => import('features/Imports/Router/MappingRoutes'));
const Packing = lazy(() => import('features/OrderFulfillment/Router/PackingRoutes'));
const Shipping = lazy(() => import('features/OrderFulfillment/Router/ShippingRoutes'));
const Packages = lazy(() => import('features/OrderFulfillment/Router/PackagesRoutes'));
const Inventory = lazy(() => import('features/Inventory/Router/InventoryRoutes'));
const Integrations = lazy(() => import('features/Integrations/Router/IntegrationRoutes'));
const Network = lazy(() => import('features/Network/Router/NetworkRouter'));
const DeliveryOptions = lazy(() => import('features/DeliveryOptions/Router/DeliveryOptionRoutes'));
const WarRoom = lazy(() => import('features/WarRoom/Router/WarRoomRoutes'));
const CycleCounts = lazy(() => import('features/CycleCounts/Router/CycleCountsRoutes'));
const WarehouseTransfers = lazy(() => import('features/WarehouseTransfers/Router/WarehouseTransferRouter'));
const Couriers = lazy(() => import('features/Couriers/CourierRouter'));

const App = () => {
  const { tenantSlug } = useAuth();

  return (
    <Page>
      <Suspense fallback={<SectionSpinner />}>
        <Switch>
          <Route
            path="/"
            exact
            component={CheckDefaultTenant}
          />
          <Route
            path="/tenant-select"
            exact
            component={TenantSelect}
          />
          <Route path="/profile" component={Account} />
          <Route path={`/${tenantSlug}`}>
            <TenantProvider>
              <Switch>
                <Route
                  path={`/${tenantSlug}/dashboard`}
                  component={Dashboard}
                />
                <Route path={`/${tenantSlug}/readiness/summary`} component={SeasonReadiness} />
                <Route path={`/${tenantSlug}/suppliers`} component={Suppliers} />
                <Route path={`/${tenantSlug}/price-plans`} component={PricePlans} />
                <Route path={`/${tenantSlug}/storage`} component={Storage} />
                <Route path={`/${tenantSlug}/vocabulary`} component={Vocabulary} />
                <Route path={`/${tenantSlug}/stock-transfers`} component={StockTransfers} />
                <Route path={`/${tenantSlug}/stock-claims`} component={StockClaims} />
                <Route path={`/${tenantSlug}/goods-receipts`} component={GoodsReceipts} />
                <Route path={`/${tenantSlug}/purchase-orders`} component={PurchaseOrders} />
                <Route path={`/${tenantSlug}/stock-adjustments`} component={StockAdjustments} />
                <Route path={`/${tenantSlug}/delivery-options`} component={DeliveryOptions} />
                <Route path={`/${tenantSlug}/war-room`} component={WarRoom} />
                <Route path={`/${tenantSlug}/settings`} component={Settings} />
                <Route path={`/${tenantSlug}/inventory/packing`} component={Packing} />
                <Route path={`/${tenantSlug}/inventory/packages`} component={Packages} />
                <Route path={`/${tenantSlug}/customers`} component={Customers} />
                <Route path={`/${tenantSlug}/inventory`} component={Inventory} />
                <Route path={`/${tenantSlug}/products`} component={Products} />
                <Route path={`/${tenantSlug}/imports`} component={Imports} />
                <Route path={`/${tenantSlug}/mappings`} component={ImportsMapping} />
                <Route path={`/${tenantSlug}/network`} component={Network} />
                <Route path={`/${tenantSlug}/reports`} component={Reports} />
                <Route path={`/${tenantSlug}/integrations`} component={Integrations} />
                <Route path={`/${tenantSlug}/orders`} component={Orders} />
                <Route path={`/${tenantSlug}/cycle-counts`} component={CycleCounts} />
                <Route path={`/${tenantSlug}/warehouse-transfers`} component={WarehouseTransfers} />
                <Route path={`/${tenantSlug}/couriers`} component={Couriers} />
                <Route path={`/${tenantSlug}/shipping`} component={Shipping} />
                <Redirect to="/" />
              </Switch>
            </TenantProvider>
          </Route>
          <Redirect to="/" />
        </Switch>
      </Suspense>
    </Page>
  );
};

export default App;
