import React, { PropsWithChildren, useCallback, useMemo } from 'react';
import { User } from 'features/Auth/Interfaces/types';
import {
  SetDefaultTenant,
  UpdateUserPasswordRequest,
  UpdateUserRequest,
} from 'features/Auth/Interfaces/requests';
import * as AuthClient from 'features/Auth/Services/AuthClient';
import { AxiosResponse } from 'axios';
import { useQuery } from 'react-query';
import { axiosInsecure } from 'lib/axios';
import { getUser } from 'features/Auth/Services/AuthClient';
import { ResultSet } from 'utils/routeique-api/types';
import SectionSpinner from 'components/SectionSpinner';

interface AuthContextValues {
  user: User | null;
  signIn: (username: string, password: string, remember: boolean) => Promise<void>;
  signOut: () => Promise<void>;
  updateUser: (
    updates: UpdateUserRequest | SetDefaultTenant
  ) => Promise<AxiosResponse<ResultSet<User>>>;
  changePassword: (updates: UpdateUserPasswordRequest) => Promise<AxiosResponse<void>>;
  tenantSlug: string;
}

const initializeCsrfAndGetUser = async (): Promise<User | null> => {
  // We must wait for the cookie request before proceeding.
  await axiosInsecure.get('/sanctum/csrf-cookie');
  const user = await getUser();
  return user || null;
};

const AuthContext = React.createContext<AuthContextValues>({} as AuthContextValues);

const AuthProvider = (props: PropsWithChildren) => {
  const {
    data: user,
    isLoading,
    isFetching,
    isError,
    refetch,
  } = useQuery('app-bootstrap', initializeCsrfAndGetUser, { retry: false, useErrorBoundary: false });

  const tenantSlug = useMemo<string>((): string => {
    if (user) {
      const options = user.tenant_users
        .filter((option) => option.permissions.indexOf('dct_access') > -1)
        .map((option) => option.tenant.url_slug)
        .join('|');
      return `:tenantSlug(${options})`;
    }
    return ':tenantSlug()';
  }, [user]);

  const signIn = useCallback(
    (
      email: string,
      password: string,
      remember: boolean,
    ) => AuthClient
      .signIn(email, password, remember)
      .then(() => {
        refetch();
      }),
    [refetch],
  );

  const signOut = useCallback(
    () => AuthClient.signOut()
      .then(() => window.location.replace('/'))
      .then(() => {
        refetch();
      }),
    [refetch],
  );

  const value = useMemo<AuthContextValues>(
    () => ({
      user: user ?? null,
      signIn,
      signOut,
      updateUser: AuthClient.updateUser,
      changePassword: AuthClient.changePassword,
      tenantSlug,
    }),
    [user, signIn, signOut, tenantSlug],
  );

  if (isLoading) {
    if (isFetching) {
      return <SectionSpinner />;
    }
    if (isError) {
      return (
        <div>
          <p>Uh oh... There&apos;s a problem. Try refreshing the app.</p>
        </div>
      );
    }
  }

  return (
    <AuthContext.Provider
      value={value}
      {...props}
    />
  );
};

function useAuth() {
  const context = React.useContext(AuthContext);
  if (context === undefined) {
    throw new Error('useAuth must be used within a AuthProvider');
  }
  return context;
}

export { AuthProvider, useAuth };
