import React, { PropsWithChildren } from 'react';

interface SectionBodyProps {
  accordion?: boolean;
}

const SectionBody = ({
  children,
  accordion = false,
}: PropsWithChildren<SectionBodyProps>) => (
  <div className={`section-body ${accordion ? 'accordion' : ''}`}>{children}</div>
);

export default SectionBody;
