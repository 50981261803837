import { NotificationPreferenceOptions } from 'features/Customers/Interfaces/types';
import { Accept } from 'react-dropzone';

export const APP_ENV = process.env.REACT_APP_ENV as string;

export const APP_VERSION = '21.3.0';

export const API_URL = process.env.REACT_APP_API_BASE_URL as string;

export const SENTRY_DSN = process.env.REACT_APP_SENTRY_DSN as string;

export const MAPBOX_TOKEN = process.env.REACT_APP_MAPBOX_TOKEN as string;

export const SUPPORT_LINK: string = 'https://support.routeique.com/kb-tickets/new';

export const MAPBOX_DEFAULT_COORDS: [[number, number], [number, number]] = [
  [-78.680804, 50],
  [-85, 42.978975],
];

export const MAPBOX_BOUNDS_PADDING = 100;

export const CLUSTER_MAX_ZOOM = 16;

export const CLUSTER_RADIUS = 15;

export const CUSTOMER_LOCATION_NOTIFICATION_PREFERENCES: NotificationPreferenceOptions = {
  order_created: [
    'email',
    'sms',
  ],
  order_delivered: [
    'email',
    'sms',
  ],
  order_reminder: [
    'email',
    'sms',
  ],
  order_rescheduled: [
    'email',
    'sms',
  ],
};

export const ACCEPTED_MIME_TYPES: Accept = {
  'image/jpeg': ['.jpg', '.jpeg'],
  'image/png': ['.png'],
  'text/plain': ['.txt'],
  'text/csv': ['.csv'],
  'application/pdf': ['.pdf'],
  'application/zip': ['.zip'],
  'application/vnd.ms-excel': ['.xls', '.xlsx'],
  'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet': ['.xls', '.xlsx'],
  'application/vnd.openxmlformats-officedocument.wordprocessingml.document': ['.xls', '.xlsx'],
  'application/msword': ['.doc', '.docx'],
};

export const TENANT_OPTIONS_IN_MENU = 3;

// The dimension at which the menu will switch from overlay mode
// to side by side mode. This should be inline with $menu-breakpoint
// in dct/src/scss/_variable_overrides.scss
export const MENU_WIDTH_OVERLAY = 1280;
