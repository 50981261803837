import React, {
  PropsWithChildren, useCallback, useEffect, useMemo,
} from 'react';
import { Helmet } from 'react-helmet-async';
import { NavDropdown } from 'react-bootstrap';
import Nav from 'react-bootstrap/Nav';
import { NavLink } from 'react-router-dom';
import Button from 'react-bootstrap/Button';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faBars, faQuestionCircle, faUser } from '@fortawesome/free-solid-svg-icons';
import useLocalStorage from 'hooks/useLocalStorage';
import Navbar from 'react-bootstrap/Navbar';
import { useTenant } from 'features/Auth/TenantContext';
import { useAuth } from 'features/Auth/AuthContext';
import { useTranslation } from 'react-i18next';
import { TenantUser } from 'features/Auth/Interfaces/types';
import { SUPPORT_LINK, TENANT_OPTIONS_IN_MENU } from 'config';
import classNames from 'classnames';
import SidebarNav from 'components/SidebarNav';
import OverlayTrigger from 'react-bootstrap/OverlayTrigger';
import Tooltip from 'react-bootstrap/Tooltip';

export interface TopNavbarProps {
  toggleMenu: () => void;
  hideSidebar?: boolean;
}

const TopNavbar = ({ toggleMenu, hideSidebar = false }: TopNavbarProps) => {
  const { t } = useTranslation('shared');
  const { tenant } = useTenant();
  const { user, signOut } = useAuth();

  const [tenantOptions, hasMore] = useMemo<[TenantUser[], boolean]>(
    () => {
      const all = (user?.tenant_users ?? [])
        .filter((option) => option.permissions.indexOf('dct_access') > -1)
        .sort((a, b) => (a.tenant.name > b.tenant.name ? 1 : -1));

      return [all.slice(0, TENANT_OPTIONS_IN_MENU), all.length > TENANT_OPTIONS_IN_MENU];
    },
    [user],
  );

  return (
    <Navbar sticky="top" id="main-navbar" className="d-flex justify-content-between">
      {!hideSidebar && (
        <Button
          variant="link"
          onClick={toggleMenu}
        >
          <FontAwesomeIcon
            icon={faBars}
            size="lg"
          />
        </Button>
      )}
      <Nav className="ml-auto" activeKey={tenant?.url_slug ?? undefined}>
        <Nav.Link target="_blank" href={SUPPORT_LINK}>
          <OverlayTrigger
            placement="bottom"
            overlay={(
              <Tooltip id="support-tooltip">
                {t('menu_item.contact_support')}
              </Tooltip>
            )}
          >
            <FontAwesomeIcon
              icon={faQuestionCircle}
              size="lg"
              className="help-icon"
            />
          </OverlayTrigger>
        </Nav.Link>
        {user && (
          <NavDropdown
            id="user"
            alignRight
            title={(
              <FontAwesomeIcon
                icon={faUser}
                size="lg"
                className="user-icon"
              />
            )}
          >
            <NavDropdown.Header>
              {t('menu_group.account')}
            </NavDropdown.Header>
            <NavDropdown.ItemText>
              {user.full_name}
              <br />
              <small>{user.email}</small>
            </NavDropdown.ItemText>
            <NavDropdown.Item>
              <Nav.Link
                as={NavLink}
                to="/profile"
              >
                {t('menu_item.edit_profile')}
              </Nav.Link>
            </NavDropdown.Item>
            <NavDropdown.Divider />
            {tenantOptions.length > 0 && (
              <>
                <NavDropdown.Header>
                  {t('menu_group.tenants')}
                </NavDropdown.Header>
                {(tenantOptions).map(({ tenant: tenantOption }) => (
                  <NavDropdown.Item key={tenantOption.url_slug}>
                    <Nav.Link
                      as={NavLink}
                      to={`/${tenantOption.url_slug}/dashboard`}
                      eventKey={`${tenantOption.url_slug}`}
                      active={tenantOption.url_slug === tenant?.url_slug}
                    >
                      {tenantOption.name}
                    </Nav.Link>
                  </NavDropdown.Item>
                ))}
                {hasMore && (
                  <NavDropdown.Item>
                    <Nav.Link
                      as={NavLink}
                      to="/tenant-select"
                      active={false}
                    >
                      {t('menu_item.other')}
                    </Nav.Link>
                  </NavDropdown.Item>
                )}
                <NavDropdown.Divider />
              </>
            )}
            <NavDropdown.Item>
              <Nav.Link
                onClick={signOut}
              >
                {t('menu_item.logout')}
              </Nav.Link>
            </NavDropdown.Item>
          </NavDropdown>
        )}
      </Nav>
    </Navbar>
  );
};

interface PageProps {
  hideSidebar?: boolean;
}

const Page = ({ children, hideSidebar = false }: PropsWithChildren<PageProps>) => {
  const { t } = useTranslation('shared');
  const [open, setOpen] = useLocalStorage<boolean>(`menu_position_${hideSidebar}`, true);
  const closeMenu = useCallback(() => setOpen(() => false), [setOpen]);
  const toggleMenu = useCallback(() => setOpen((prev) => !prev), [setOpen]);

  // We fire a resize event after the CSS transitions are complete,
  // otherwise things that listen to the event (ie Mapbox) will not
  // pick up the resize event correctly.
  useEffect(() => {
    setTimeout(() => {
      window.dispatchEvent(new Event('resize'));
    }, 400);
  }, [open]);

  useEffect(() => {
    if (hideSidebar) {
      closeMenu();
    }
  }, [closeMenu, hideSidebar]);

  const contentClasses = classNames({ active: open });

  const overlayClassNames = classNames('overlay', {
    active: open,
  });

  return (
    <>
      <Helmet
        titleTemplate={`%s - ${t('headings.title')}`}
        defaultTitle={t('headings.title')}
      >
        <meta
          name="description"
          content={t('messages.description')}
        />
      </Helmet>
      <div id="wrapper">
        <SidebarNav
          isOpen={open}
          close={closeMenu}
        />
        <div id="main" className={contentClasses}>
          <TopNavbar
            toggleMenu={toggleMenu}
            hideSidebar={hideSidebar}
          />
          <div id="content">
            {children}
          </div>
        </div>
      </div>
      <div
        onClick={closeMenu}
        role="presentation"
        className={overlayClassNames}
      />
    </>
  );
};

// TODO With the menu revamp this really isn't needed anymore and can be removed.
export const withPage = <P extends {}>(Component: React.ComponentType<P>) => ({ ...props }: P) => (
  <Component {...(props as P)} />
);

export default Page;
