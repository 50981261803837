import { SidebarNavGroup } from 'components/SidebarNav/types';
import { customReportsPermissions } from 'features/Reports/config';

// __TENANT_SLUG__ will be replaced with current tenant's slug
// eslint-disable-next-line import/prefer-default-export
export const menuLinks: SidebarNavGroup[] = [
  {
    groupTranslationKey: 'customers',
    items: [
      {
        to: '/__TENANT_SLUG__/customers',
        label: 'search_customers',
        permission: 'view_customers',
      },
      {
        to: '/__TENANT_SLUG__/customers/create',
        label: 'new_customer',
        permission: 'create_customers',
      },
    ],
  },
  {
    groupTranslationKey: 'orders',
    items: [
      {
        to: '/__TENANT_SLUG__/orders',
        label: 'search_orders',
        permission: 'view_orders',
      },
      {
        to: '/__TENANT_SLUG__/orders/create',
        label: 'create_order',
        permission: 'create_orders_dct',
      },
      {
        to: '/__TENANT_SLUG__/orders/groups',
        label: 'order_groups',
        permission: 'view_order_groups',
      },
      {
        to: '/__TENANT_SLUG__/orders/standing',
        label: 'standing_orders',
        permission: 'view_standing_orders',
      },
      {
        to: '/__TENANT_SLUG__/orders/automatic',
        label: 'automatic_orders',
        permission: 'view_default_order_items',
      },
      {
        to: '/__TENANT_SLUG__/orders/pick-list',
        label: 'pick_list_orders',
        permission: 'view_pick_list',
      },
    ],
  },
  {
    groupTranslationKey: 'products',
    items: [
      {
        to: '/__TENANT_SLUG__/products',
        permission: 'view_products',
        label: 'products',
      },
      {
        to: '/__TENANT_SLUG__/products/create',
        permission: 'create_products',
        label: 'create_product',
      },
      {
        to: '/__TENANT_SLUG__/suppliers',
        label: 'suppliers',
        permission: 'view_suppliers',
      },
      {
        to: '/__TENANT_SLUG__/price-plans',
        label: 'price_plans',
        permission: 'read_price_plans',
      },
    ],
  },
  {
    groupTranslationKey: 'inventory',
    items: [
      {
        to: '/__TENANT_SLUG__/cycle-counts',
        label: 'cycle_counts',
        permission: 'view_cycle_count',
      },
      {
        to: '/__TENANT_SLUG__/purchase-orders',
        permission: 'view_purchase_orders',
        label: 'inbound',
      },
      {
        to: '/__TENANT_SLUG__/inventory',
        label: 'inventory_management',
        permission: 'view_inventory_management',
      },
      {
        to: '/__TENANT_SLUG__/inventory/packages',
        permission: 'view_packages',
        label: 'packages',
      },
      {
        to: '/__TENANT_SLUG__/inventory/packing',
        label: 'packing',
        permission: 'view_packing_list',
      },
      {
        to: '/__TENANT_SLUG__/stock-adjustments',
        label: 'stock_adjustments',
        permission: 'view_stock_adjustments',
      },
      {
        to: '/__TENANT_SLUG__/stock-claims',
        label: 'stock_claims',
        permission: 'view_stock_claims',
      },
      {
        to: '/__TENANT_SLUG__/stock-transfers',
        label: 'stock_transfers',
        permission: 'view_stock_transfers',
      },
      {
        to: '/__TENANT_SLUG__/storage',
        permission: 'view_storage_locations',
        label: 'storage',
      },
      {
        to: '/__TENANT_SLUG__/warehouse-transfers',
        permission: 'view_warehouse_transfer',
        label: 'warehouse_transfers',
        feature: 'warehouse_transfers',
      },
    ],
  },
  {
    groupTranslationKey: 'delivery_management',
    items: [
      {
        to: '/__TENANT_SLUG__/delivery-options',
        label: 'service_areas_and_routes',
        permission: 'view_service_areas_and_assigned_routes',
      },
      {
        to: '/__TENANT_SLUG__/couriers',
        label: 'couriers',
        feature: 'courier',
        permission: 'view_couriers',
      },
      {
        to: '/__TENANT_SLUG__/shipping',
        label: 'shipping',
        feature: 'courier',
        permission: 'view_shipping',
      },
      {
        to: '/__TENANT_SLUG__/delivery-options/route-plan',
        label: 'tour_planner',
        permission: 'view_tour_planner',
      },
      {
        to: '/__TENANT_SLUG__/war-room',
        label: 'war_room_map',
        permission: 'view_war_room_map',
      },
    ],
  },
  {
    groupTranslationKey: 'assets',
    items: [
      {
        to: '/__TENANT_SLUG__/readiness/summary',
        label: 'season_readiness',
      },
    ],
  },
  {
    groupTranslationKey: 'reports',
    items: [
      {
        to: '/__TENANT_SLUG__/reports',
        label: 'custom_reports',
        permission: customReportsPermissions,
        or: true,
      },
      {
        to: '/__TENANT_SLUG__/reports/delivery-sequence-summary',
        label: 'delivery_report',
        permission: 'view_delivery_report',
      },
      {
        to: '/__TENANT_SLUG__/reports/margin-report',
        label: 'margin_report',
        permission: 'view_margin_report',
      },
      {
        to: '/__TENANT_SLUG__/reports/time-report',
        label: 'time_report',
        permission: 'view_tour_time_report',
      },
    ],
  },
  {
    groupTranslationKey: 'settings',
    items: [
      {
        to: '/__TENANT_SLUG__/vocabulary',
        label: 'vocabulary',
        permission: 'manage_vocabulary',
      },
      {
        to: '/__TENANT_SLUG__/settings',
        label: 'settings',
        permission: 'view_general_settings',
      },
      {
        to: '/__TENANT_SLUG__/imports',
        label: 'imports',
        permission: 'view_imports',
      },
      {
        to: '/__TENANT_SLUG__/integrations',
        label: 'integrations',
        permission: 'view_integrations',
      },
      {
        to: '/__TENANT_SLUG__/settings/inventory',
        label: 'inventory',
        permission: 'view_inventory_settings',
      },
      {
        to: '/__TENANT_SLUG__/settings/orders',
        label: 'orders',
        permission: 'view_order_settings',
      },
      {
        to: '/__TENANT_SLUG__/network',
        label: 'network',
        permission: 'view_order_sharing_agreements',
      },
      {
        to: '/__TENANT_SLUG__/settings/templates/invoices',
        label: 'invoice_templates',
        permission: 'manage_template_translations',
      },
      {
        to: '/__TENANT_SLUG__/settings/fulfillment',
        label: 'fulfillment',
        permission: 'view_tour_settings',
      },
      {
        to: '/__TENANT_SLUG__/settings/users',
        label: 'users',
        permission: 'view_users',
      },
    ],
  },
];
