import { Theme } from '@nivo/core';
import { BarDatum, BarSvgProps } from '@nivo/bar';
import { PieSvgProps } from '@nivo/pie';
import { DynamicReportTypeWithPermission } from './Interfaces/types';
import { TenantUserPermissions } from '../Auth/Interfaces/types';

export const reportTypes : DynamicReportTypeWithPermission[] = [
  {
    reportType: 'delivery_shorts_by_order_item_delivery_short',
    permission: 'view_create_delivery_shorts_by_order_item_report',
  },
  {
    reportType: 'delivery_shorts_by_reason_code',
    permission: 'view_create_delivery_shorts_by_reason_code_report',
  },
  {
    reportType: 'fulfillment_short',
    permission: 'view_create_fulfillment_shorts_report',
  },
  {
    reportType: 'sales_by_created_by_user',
    permission: 'view_create_sales_by_order_creator_report',
  },
  {
    reportType: 'sales_by_customer',
    permission: 'view_create_sales_by_customer_report',
  },
  {
    reportType: 'sales_by_location',
    permission: 'view_create_sales_by_location_report',
  },
  {
    reportType: 'sales_by_order',
    permission: 'view_create_sales_by_order_report',
  },
  {
    reportType: 'sales_by_order_group',
    permission: 'view_create_sales_by_order_group_report',
  },
  {
    reportType: 'sales_by_order_item',
    permission: 'view_create_sales_by_order_item_report',
  },
  {
    reportType: 'sales_by_product',
    permission: 'view_create_sales_by_product_report',
  },
  {
    reportType: 'sales_by_sales_rep',
    permission: 'view_create_sales_by_sales_rep_report',
  },
  {
    reportType: 'sales_by_tour',
    permission: 'view_create_sales_by_tour_report',
  },
  {
    reportType: 'inventory_history',
    permission: 'view_create_sales_by_inventory_history_report',
  },
  {
    reportType: 'lot_fulfillment_by_order',
    permission: 'view_create_lot_fulfillment_by_order_report',
  },
  {
    reportType: 'packing_actions',
    permission: 'view_create_packing_actions_report',
  },
  {
    reportType: 'lot_fulfillment_by_package_content',
    permission: 'view_create_lot_fulfillment_by_package_content_report',
  },
];

export const customReportsPermissions: TenantUserPermissions[] = reportTypes
  .map(({ permission }) => permission).concat('view_create_all_custom_report_types');

export const nivoTheme: Theme = {
  fontSize: 14,
};

export const defaultResponsivePieProps: Partial<PieSvgProps<any>> = {
  theme: nivoTheme,
  margin: {
    top: 40, right: 100, bottom: 40, left: 100,
  },
  innerRadius: 0.5,
  padAngle: 0.7,
  cornerRadius: 3,
  colors: { scheme: 'set2' },
  borderWidth: 1,
  borderColor: { from: 'color', modifiers: [['darker', 0.2]] },
};

export const defaultResponsiveBarProps: Partial<BarSvgProps<BarDatum>> = {
  margin: {
    top: 50, right: 60, bottom: 50, left: 60,
  },
  padding: 0.3,
  colors: { scheme: 'set2' },
  borderWidth: 1,
  borderColor: { from: 'color', modifiers: [['darker', 0.2]] },
  labelTextColor: '#333333',
  labelSkipWidth: 12,
  labelSkipHeight: 12,
};
