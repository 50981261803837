import React, {
  PropsWithChildren, useCallback, useEffect, useMemo,
} from 'react';
import _ from 'lodash';
import {
  SettingKey, Tenant, TenantSettings, TenantUser, TenantUserPermissions,
} from 'features/Auth/Interfaces/types';
import { Redirect, useParams } from 'react-router-dom';
import { useAuth } from 'features/Auth/AuthContext';
import { useQuery } from 'react-query';
import { TenantNode } from 'features/Network/Interfaces/types';
import { TenantNodeStoreRequest } from 'features/Network/Interfaces/NetworkRequest';
import TenantNodeService from 'features/Network/Services/TenantNodeService';
import { useMenu } from 'components/SidebarNav/MenuContext';
import SectionSpinner from 'components/SectionSpinner';
import SettingsService from 'features/Settings/Services/SettingsService';

export type GetSettingCallback = <T extends SettingKey>(setting: T) => TenantSettings[T];

export type GetSettingCallbackAsync = <T extends SettingKey>(setting: T)
  => Promise<TenantSettings[T]>;

export type CanCallback = (
  permissions: TenantUserPermissions | TenantUserPermissions[],
  or?: boolean
) => boolean;

export interface TenantContextValue {
  tenant: Tenant;
  tenantUser: TenantUser;

  tenantSettings: TenantSettings;
  getSetting: GetSettingCallback;
  // Support for the previous implementation's API. Should shift to
  // using the non-promise based getSetting due to change in how
  // we haul in tenant settings.

  can: CanCallback;

  tenantNode?: TenantNode;
  storeTenantNode: (values: TenantNodeStoreRequest) => Promise<void>;
}

interface TenantRouteProps {
  tenantSlug: string;
}

const TenantContext = React.createContext<TenantContextValue>({} as TenantContextValue);

const TenantProvider = ({ children }: PropsWithChildren) => {
  const { user } = useAuth();
  const { tenantSlug } = useParams<TenantRouteProps>();
  const tenantUser = useMemo<TenantUser | null>(() => {
    const found = _.find(
      user?.tenant_users,
      ({ tenant }: TenantUser) => tenant.url_slug === tenantSlug,
    );
    return found ?? null;
  }, [user, tenantSlug]);
  const {
    setTenantSlug, setTenantName, setCanCallback, setTenantFeatures,
  } = useMenu();

  const { data: tenantSettings, isLoading } = useQuery(
    ['tenantSettings', tenantUser?.tenant.id],
    async () => {
      if (!tenantUser?.tenant.id) {
        return undefined;
      }
      const response = await SettingsService.getSettings(tenantUser.tenant.id);
      return response.data.data;
    },
  );

  const getSetting = useCallback<GetSettingCallback>(
    <T extends SettingKey>(setting: T): TenantSettings[T] => {
      if (!tenantSettings) {
        throw new Error('Tenant not set');
      }
      return tenantSettings[setting];
    },
    [tenantSettings],
  );

  const can = useCallback((
    permissions: TenantUserPermissions | TenantUserPermissions[],
    or = false,
  ) => {
    if (!tenantUser || !user) {
      return false;
    }
    if (user.roles.some((role) => role === 'super_admin')) {
      return true;
    }
    let valid = true;
    if (!Array.isArray(permissions)) {
      return tenantUser.permissions.indexOf(permissions) !== -1;
    }
    // If or is false, the user must have all permissions received.
    if (!or) {
      permissions.forEach((perm) => {
        if (tenantUser.permissions.indexOf(perm) === -1) {
          valid = false;
        }
      });
    } else {
      // Otherwise they only need to have one permission.
      let count = 0;
      permissions.forEach((perm) => {
        if (tenantUser.permissions.indexOf(perm) !== -1) {
          count += 1;
        }
      });
      // The user didn't have a single permission, so we mark this false.
      if (count === 0) {
        valid = false;
      }
    }
    return valid;
  }, [user, tenantUser]);

  const { data: tenantNode, refetch: refetchTenantNode } = useQuery(
    ['tenantNode', tenantUser?.tenant.id],
    async () => {
      if (!tenantUser?.tenant.id) {
        return undefined;
      }
      const response = await TenantNodeService.show(tenantUser.tenant.id);
      return response.data.data;
    },
    { retry: false, useErrorBoundary: false },
  );

  const storeTenantNode = useCallback(async (values: TenantNodeStoreRequest) => {
    if (tenantUser) {
      TenantNodeService.store(tenantUser.tenant.id, values)
        .then(() => refetchTenantNode());
    }
  }, [refetchTenantNode, tenantUser]);

  useEffect(() => {
    setTenantSlug(tenantSlug);
    setTenantName(tenantUser?.tenant.name);
    setCanCallback(can);
    setTenantFeatures(tenantUser ? tenantUser.tenant.features : undefined);

    return () => {
      setTenantSlug();
      setTenantName();
      setCanCallback();
      setTenantFeatures();
    };
  }, [
    can,
    setCanCallback,
    setTenantFeatures,
    setTenantName,
    setTenantSlug,
    tenantSlug,
    tenantUser,
  ]);

  const value = useMemo(() => ({
    tenant: tenantUser?.tenant,
    tenantUser,
    tenantSettings,
    getSetting,
    can,
    tenantNode,
    storeTenantNode,
  }), [
    tenantUser,
    tenantSettings,
    getSetting,
    can,
    tenantNode,
    storeTenantNode,
  ]);

  if (isLoading) {
    return <SectionSpinner />;
  }

  if (tenantUser === null || !tenantSettings || isLoading) {
    return <Redirect to="/" />;
  }

  return (
    <TenantContext.Provider
      // @ts-ignore
      value={value}
    >
      {children}
    </TenantContext.Provider>
  );
};

function useTenant() {
  const context = React.useContext(TenantContext);
  if (context === undefined) {
    throw new Error('useTenant must be used within a TenantProvider');
  }
  return context;
}

export { TenantProvider, useTenant, TenantContext };
