import i18n from 'i18next';
import LanguageDetector from 'i18next-browser-languagedetector';
import { initReactI18next } from 'react-i18next';
import auth from 'assets/lang/en/auth.json';
import billing from 'assets/lang/en/billing.json';
import customers from 'assets/lang/en/customers.json';
import couriers from 'assets/lang/en/couriers.json';
import cycle_counts from 'assets/lang/en/cycle_counts.json';
import delivery_options from 'assets/lang/en/delivery_options.json';
import file_dropzone from 'assets/lang/en/file_dropzone.json';
import imports from 'assets/lang/en/imports.json';
import inbound_logistics from 'assets/lang/en/inbound_logistics.json';
import integrations from 'assets/lang/en/integrations.json';
import inventory from 'assets/lang/en/inventory.json';
import maintenance_mode from 'assets/lang/en/maintenance_mode.json';
import network from 'assets/lang/en/network.json';
import order_fulfillment from 'assets/lang/en/order_fulfillment.json';
import orders from 'assets/lang/en/orders.json';
import pagination from 'assets/lang/en/pagination.json';
import price_plans from 'assets/lang/en/price_plans.json';
import printing from 'assets/lang/en/printing.json';
import products from 'assets/lang/en/products.json';
import reports from 'assets/lang/en/reports.json';
import season_readiness from 'assets/lang/en/season_readiness.json';
import settings from 'assets/lang/en/settings.json';
import shared from 'assets/lang/en/shared.json';
import stock_adjustments from 'assets/lang/en/stock_adjustments.json';
import stock_claims from 'assets/lang/en/stock_claims.json';
import stock_transfers from 'assets/lang/en/stock_transfers.json';
import storage from 'assets/lang/en/storage.json';
import suppliers from 'assets/lang/en/suppliers.json';
import vocabulary from 'assets/lang/en/vocabulary.json';
import war_room from 'assets/lang/en/war_room.json';
import warehouse_transfers from 'assets/lang/en/warehouse_transfers.json';

export const defaultNS = 'shared';

export const resources = {
  en: {
    auth,
    billing,
    couriers,
    customers,
    cycle_counts,
    delivery_options,
    file_dropzone,
    imports,
    inbound_logistics,
    integrations,
    inventory,
    maintenance_mode,
    network,
    order_fulfillment,
    orders,
    pagination,
    price_plans,
    printing,
    products,
    reports,
    season_readiness,
    settings,
    shared,
    stock_adjustments,
    stock_claims,
    stock_transfers,
    storage,
    suppliers,
    vocabulary,
    war_room,
    warehouse_transfers,
  },
} as const;

i18n
  .use(LanguageDetector)
  .use(initReactI18next)
  .init({
    detection: {
      lookupFromPathIndex: 0,
      order: [
        'path',
        'querystring',
        'cookie',
        'localStorage',
        'sessionStorage',
        'navigator',
        'htmlTag',
        'subdomain',
      ],
    },
    fallbackLng: 'en',
    supportedLngs: ['en'],
    defaultNS,
    ns: ['shared'],
    // we do not use keys in form messages.welcome
    interpolation: {
      // https://www.i18next.com/translation-function/interpolation
      skipOnVariables: false,
      escapeValue: false, // react already safe from xss
    },
    load: 'currentOnly',
    // We disable this and handle it ourselves in App.tsx
    react: {
      useSuspense: false,
    },
    // Turn this on if you're having troubles with translations
    debug: false,
    resources,
  });

export default i18n;
