import routeiqueApiRequest from 'utils/routeique-api/routeiqueApiRequest';
import { ResultSet } from '../../Shared/Interfaces/api/api';
import { TenantNode } from '../Interfaces/types';
import { TenantNodeStoreRequest } from '../Interfaces/NetworkRequest';

export default class TenantNodeService {
  public static show(
    tenant_id: number,
  ) {
    return routeiqueApiRequest<ResultSet<TenantNode>>(
      `${tenant_id}/network/node`,
      'get',
    );
  }

  public static availableNodes(
    tenant_id: number,
  ) {
    return routeiqueApiRequest<ResultSet<TenantNode[]>>(
      `${tenant_id}/network/available-nodes`,
      'get',
    );
  }

  public static store(
    tenant_id: number,
    values: TenantNodeStoreRequest,
  ) {
    return routeiqueApiRequest<ResultSet<TenantNode>>(
      `${tenant_id}/network/node`,
      'post',
      values,
    );
  }
}
