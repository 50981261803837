import React, { PropsWithChildren } from 'react';
import { Router } from 'react-router-dom';
import { HelmetProvider } from 'react-helmet-async';
import 'lib/i18n';
import { QueryClientProvider } from 'react-query';
import { MaintenanceModeProvider } from 'features/maintenance-mode/MaintenanceModeContext';
import { AuthProvider } from 'features/Auth/AuthContext';
import { ReactQueryDevtools } from 'react-query/devtools';
import * as Sentry from '@sentry/react';
import { InvalidateQueryProvider, queryClient } from 'lib/react-query';
import { history } from 'lib/history';
import ReactToastify from 'lib/react-toastify';
import { ReactRouterBackStackContextProvider } from 'utils/react-router-back-stack';
import { MenuProvider } from 'components/SidebarNav/MenuContext';

// eslint-disable-next-line import/prefer-default-export
export const AppProviders = ({ children }: PropsWithChildren) => {
  return (
    <Sentry.ErrorBoundary
      fallback={<p>An error has occurred</p>}
      showDialog
    >
      <Router history={history}>
        <ReactRouterBackStackContextProvider>
          <HelmetProvider>
            <QueryClientProvider client={queryClient}>
              <InvalidateQueryProvider>
                <MaintenanceModeProvider>
                  <AuthProvider>
                    <MenuProvider>
                      {children}
                    </MenuProvider>
                    <ReactToastify />
                  </AuthProvider>
                </MaintenanceModeProvider>
                <ReactQueryDevtools />
              </InvalidateQueryProvider>
            </QueryClientProvider>
          </HelmetProvider>
        </ReactRouterBackStackContextProvider>
      </Router>
    </Sentry.ErrorBoundary>
  );
};
