import React from 'react';
import { Slide, ToastContainer } from 'react-toastify';

// React Toastify requires CSS, which is bundled with scss/App.scss
// react-toastify/dist/ReactToastify.min.css

const ReactToastify = () => {
  return (
    <div data-test="toast-container">
      <ToastContainer
        position="top-right"
        autoClose={5000}
        hideProgressBar
        closeOnClick
        pauseOnHover
        draggable={false}
        transition={Slide}
        rtl={false}
        closeButton={false}
        icon={false}
      />
    </div>
  );
};

export default ReactToastify;
