import { useAuth } from 'features/Auth/AuthContext';
import Protected from './protected';
import Public from './public';

// eslint-disable-next-line import/prefer-default-export
export const AppRoutes = () => {
  const auth = useAuth();

  return auth.user ? <Protected /> : <Public />;
};
