import React, { PropsWithChildren } from 'react';
import Row from 'react-bootstrap/Row';
import classNames from 'classnames';

interface SectionBodyProps {
  equalHeight?: boolean;
}

const SectionRow = ({ children, equalHeight = false }: PropsWithChildren<SectionBodyProps>) => {
  const className = classNames('section-row', { 'equal-height': equalHeight });
  return (
    <Row className={className}>{children}</Row>
  );
};

export default SectionRow;
