import React, {
  PropsWithChildren, useEffect, useMemo, useState,
} from 'react';
// eslint-disable-next-line import/no-extraneous-dependencies
import { Location } from 'history';
import { useHistory } from 'react-router-dom';

interface InterfaceReactRouterBackStackContext {
  backStack: Location[];
  lastLocation: Location | null;
}

const ReactRouterBackStackContext = React.createContext<InterfaceReactRouterBackStackContext>(
  undefined as unknown as InterfaceReactRouterBackStackContext,
);

function ReactRouterBackStackContextProvider(props: PropsWithChildren<{}>) {
  const history = useHistory();
  const [backStack, setBackStack] = useState<Location[]>([]);
  useEffect(() => {
    // history.listen returns an unregister callback to avoid
    // memory leaks, therefore we return it here.
    return history.listen((location, action) => {
      setBackStack((prevBackStack) => {
        switch (action) {
          case 'POP':
            return prevBackStack.slice(0, prevBackStack.length - 1);
          case 'PUSH':
            return [...prevBackStack, location];
          case 'REPLACE':
            return [...prevBackStack.slice(0, prevBackStack.length - 1), location];
          default:
            throw new Error('Unknown Action');
        }
      });
    });
  }, [history]);

  const lastLocation = useMemo<Location | null>(
    () => backStack[backStack.length - 2] ?? null,
    [backStack],
  );

  const contextValue = useMemo<InterfaceReactRouterBackStackContext>(() => ({
    lastLocation,
    backStack,
  }), [lastLocation, backStack]);

  return (
    <ReactRouterBackStackContext.Provider
      value={contextValue}
      {...props}
    />
  );
}

function useBackStack() {
  const context = React.useContext(ReactRouterBackStackContext);
  if (context === undefined) {
    throw new Error('useBackStack must be used within a ReactRouterBackStackContextProvider');
  }
  return context;
}

export { ReactRouterBackStackContextProvider, useBackStack };
