/**
 * For more information on how to use this function, visit the following page:
 * https://routeique.atlassian.net/wiki/spaces/RP/pages/968523899
 */
import { AxiosPromise, AxiosRequestConfig, Method } from 'axios';
import { axiosInstance } from 'lib/axios';

const BASE_PREFIX = '/api/';

export const getConfig = <D>(
  url: string,
  configuration: AxiosRequestConfig<D>,
  method: Method,
  requestBody: D,
): AxiosRequestConfig<D> => {
  const config: AxiosRequestConfig<D> = {
    ...configuration,
    url: BASE_PREFIX + url.replace(/^\/+/g, ''),
    method,
  };

  if (method === 'get' || method === 'GET') {
    return { ...config, params: requestBody };
  }

  return { ...config, data: requestBody };
};

export default function routeiqueApiRequest<T, D extends object = {}>(
  url: string,
  method: Method = 'get',
  body: any = {},
  configuration: AxiosRequestConfig<D> = {},
): AxiosPromise<T> {
  return axiosInstance(getConfig<D>(url, configuration, method, body));
}
