import React, { PropsWithChildren } from 'react';
import SectionBody from 'components/SectionBody';
import SectionHeader from 'components/SectionHeader';
import SectionFooter from 'components/SectionFooter';
import SectionTitle from 'components/SectionTitle';
import SectionRow from 'components/SectionRow/SectionRow';

interface SectionProps {
  dataTest?: string;
}
const Section = ({
  children,
  dataTest = 'section',
}: PropsWithChildren<SectionProps>) => (
  <div className="section" data-test={`${dataTest}-section`}>{children}</div>
);

export default Object.assign(Section, {
  Row: SectionRow,
  Header: SectionHeader,
  Title: SectionTitle,
  Body: SectionBody,
  Footer: SectionFooter,
});
