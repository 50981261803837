import React from 'react';
import 'scss/App.scss';
import { AppProviders } from 'providers';
import { enableSentryIfDsnPresent } from 'lib/sentry';
import { AppRoutes } from 'routes';

enableSentryIfDsnPresent();

const App = () => {
  return (
    <AppProviders>
      <AppRoutes />
    </AppProviders>
  );
};

export default App;
