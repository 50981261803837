import * as Sentry from '@sentry/react';
import { APP_ENV, SENTRY_DSN } from 'config';
import { BrowserTracing } from '@sentry/tracing';
import { history } from 'lib/history';

export const enableSentryIfDsnPresent = (): void => {
  if (SENTRY_DSN) {
    Sentry.init({
      dsn: SENTRY_DSN,
      environment: APP_ENV,
      integrations: [
        new BrowserTracing({
          routingInstrumentation: Sentry.reactRouterV5Instrumentation(history),
        }),
      ],
    });
  }
};
